<template>
  <div class="container" align="center">
    <div class="inner-container login">
      <img class="emojise-logo" src="@/assets/images/logo.png"  alt="Logo">
      <h2 class="top-header">Nice to meet you ✌️</h2>
      <span>Sign up for an account</span>
      <form @submit.prevent="handleSignup">
        <input type="text" placeholder="Name" v-model="name" required style="margin-top: 0">
        <input type="email" placeholder="Email Address" v-model="email" required>
        <input :type="(isHidden) ? 'password' : 'text'" placeholder="Password" v-model="password" required>
        <span class="toggle-password" @click="isHidden = !isHidden" title="Show Password">
          <i v-if="isHidden" class="fas fa-eye"></i>
          <i v-else class="fas fa-eye-slash"></i>
        </span>
        <div v-if="error" class="error">{{ error }}</div>
        <button v-if="!isLoading">Create Account</button>
        <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
      </form>
      <p>Already Registered? <router-link :to="{ name: 'Login' }" style="color: var(--link);">Log In</router-link></p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/composables/useAuth'

export default {
  setup() {
    const router = useRouter()
    const { signup, error, isLoading } = useAuth()
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const isHidden = ref(true)

    const handleSignup = async () => {
      await signup(name.value, email.value, password.value)

      if (!error.value) {
        router.push({ name: 'Queue' })
      }
    }

    return { name, email, password, isHidden, handleSignup, error, isLoading }
  }
}
</script>

<style scoped>
  .emojise-logo {
    margin: 15px auto;
  }
  input {
    margin: 12px auto;
  }
  button {
    margin: 20px auto 0 auto;
  }
  .toggle-password {
    float: right;
    margin-top: -45px;
    cursor: pointer;
  }
</style>